import React, { useState } from 'react';
import {
  AndreyMakarin,
  AndreyMaksimenko,
  BackgroundImage,
  DianaMardas,
  Dubatovka,
  FooterPapaPro,
  InfoBlock,
  PapaEveryDay,
  PapaProTitle,
  leonid,
  olga,
  putik,
  ruslanDoroshevich,
} from 'assets/images';
import { TitleH1 } from 'components/Text/TitleH1/TitleH1';
import './WaitingBabyPage.style.css';

const experts = [
  {
    name: 'Диана Мардас',
    title: 'Врач акушер-гинеколог',
    photo: DianaMardas,
  },
  {
    name: 'Руслан Дорошевич',
    title: 'Врач-уролог, андролог',
    photo: ruslanDoroshevich,
  },
  {
    name: 'Андрей Максименко',
    title: 'Психолог',
    photo: AndreyMaksimenko,
  },
  {
    name: 'Надежда Дубатовка',
    title: 'Врач-педиатр',
    photo: Dubatovka,
  },
  {
    name: 'Андрей Макарин-Кибак',
    title: 'Врач-отоларинголог',
    photo: AndreyMakarin,
  },
  {
    name: 'Леонид Шалькевич',
    title: 'Врач детский невролог',
    photo: leonid,
  },
  {
    name: 'Юлиан Путик',
    title: 'Врач травматолог-ортопед',
    photo: putik,
  },
  {
    name: 'Ольга Кашляк',
    title: 'Врач-педиатр',
    photo: olga,
  },
];

const WaitingBabyPageFD = () => {
  const [showAll, setShowAll] = useState(false);

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  return (
    <div className="page-background">
      <main className="mzr_first">
        <article>
          <section className="mzr_first-container">
            <div className="course-intro">
              <img src={PapaProTitle} alt="Курс Папа каждый день" />
            </div>
            <div className="course-host">
              <h4
                className="course-host-title"
                style={{
                  fontFamily: 'Raleway',
                  fontSize: '20px',
                  fontWeight: '700',
                  color: 'black',
                  marginTop: -10,
                }}
              >
                Ольга Атрощенко
              </h4>
              <p
                className="course-host-description"
                style={{
                  fontFamily: 'Raleway',
                  fontWeight: '400',
                  fontSize: '16px',
                  marginTop: -20,
                }}
              >
                Глава офиса Фонда ООН в области <br /> народонаселения (ЮНФПА) в
                Беларуси
              </p>
            </div>

            <div
              className="video-container"
              style={{
                marginTop: -40,
              }}
            >
              <iframe
                src={
                  'https://player.vimeo.com/video/990557282?title=0&byline=0&portrait=0&controls=1'
                }
                width="100%"
                height="800px"
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
                className="video-frame"
              ></iframe>
            </div>
            <div className="experts">
              <div>
                <p className="сourse">🎓 эксперты курса</p>
                <p className="сourseSubtitle">Врачи и психологи</p>
              </div>

              <div className={`expertsList ${showAll ? 'show-all' : ''}`}>
                <ul>
                  {experts.map((expert, index) => (
                    <li key={index} className="expertsAll">
                      <img src={expert.photo} alt={expert.name} />
                      <span>
                        <span className="name">{expert.name}</span>
                        <span>{expert.title}</span>
                      </span>
                    </li>
                  ))}
                </ul>
                {!showAll && <div className="overlay"></div>}
              </div>

              <button className="custom-button" onClick={toggleShowAll}>
                {showAll ? 'Скрыть' : 'Показать всех экспертов'}
              </button>
            </div>
            <img
              src={PapaEveryDay}
              alt="Курс Папа каждый день"
              className="course-image"
            />
            <img
              src={InfoBlock}
              alt="Курс Папа каждый день"
              className="course-image"
            />
            <img
              src={BackgroundImage}
              alt="Курс Папа каждый день"
              className="course-image"
            />

            <div className="info-blocks"></div>

            <img
              src={FooterPapaPro}
              alt="Курс Папа каждый день"
              className="course-image"
            />
          </section>
        </article>
      </main>
    </div>
  );
};

export default WaitingBabyPageFD;
