import React, { FC, useCallback } from 'react';
import styles from './UserPhoto.module.css';
import { useAppSelector, useAppDispatch } from 'store/store';
import { selectUser } from 'features/User/store/selectors';
import { Avatar } from 'components/Avatar/Avatar';
import { EditButton } from '../Buttons/EditButton/EditButton';
import { DeleteButton } from '../Buttons/DeleteButton/DeleteButton';
import { updateDbUser } from 'api/userApi';
import { useTranslate } from 'hooks/useTranslate';
import { useAppWindowSize } from 'hooks/useAppWindowSize';
import { showLoader, hideLoader } from 'features/Settings/store/OverlaySlice';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage'; // Firebase web SDK

export const UserPhoto: FC = () => {
  const t = useTranslate();
  const user = useAppSelector(selectUser);
  const dispatch = useAppDispatch();
  const { isMobile } = useAppWindowSize();

  const handleFileSelect = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (!file) return;

      dispatch(showLoader());

      try {
        const storage = getStorage();
        const storageRef = ref(storage, `images/${file.name}`);

        await uploadBytes(storageRef, file);

        const downloadURL = await getDownloadURL(storageRef);

        await updateDbUser({ photoURL: downloadURL });
      } catch (error) {
        console.error('Error updating the photo URL:', error);
        alert(t('error_db_update'));
      } finally {
        dispatch(hideLoader());
      }
    },
    [dispatch, t]
  );

  const handleButtonClick = useCallback(() => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    // @ts-ignore
    input.onchange = handleFileSelect;
    input.click();
  }, [handleFileSelect]);

  const handleDeletePhoto = useCallback(async () => {
    dispatch(showLoader());
    try {
      await updateDbUser({ photoURL: null });
    } catch {
      alert(t('error_db_update'));
    } finally {
      dispatch(hideLoader());
    }
  }, [dispatch, t]);

  return (
    <div className={styles.container}>
      <div>
        <Avatar
          url={user?.photoURL}
          big
          containerStyle={{ marginTop: isMobile ? 30 : undefined }}
        />
      </div>

      <div className={styles.controlsContainer}>
        <EditButton
          isEmpty={!!user?.photoURL}
          title={t(!!user?.photoURL ? 'common-change' : 'settings_photo_add')}
          onPress={handleButtonClick}
        />
        {!!user?.photoURL && (
          <DeleteButton
            title={t('common-delete')}
            onPress={handleDeletePhoto}
          />
        )}
      </div>
    </div>
  );
};
